import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function AccountPage() {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse: ", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => {
          console.log(err)

        });
    }
  }, [user]);

  const handleLogout = () => {
    googleLogout();
    setProfile(null);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    console.log("user", profile);
    let data = JSON.stringify({
      "name": profile.name,
      "contact": profile.email,
      "type": "delete"
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://renderbeservice-862907-teleinit.onrender.com/api/tickets/delete',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
      .then((response) => {
        console.log(response.data);
        // Resetting form and states
        setIsChecked1(false);
        setIsChecked2(false);
        setIsSubmitting(false);
        alert("Request submitted successfully")
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsSubmitting(false);
        alert("Something went wrong. Try again later.")
      });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Sumbit Account Deletion Request-Arogriha</h2>
      <br />
      {profile ? (
        <div>
          <img src={profile.picture} alt="user image" />
          <h3>User Logged in</h3>
          <p>Name: {profile.name}</p>
          <p>Email Address: {profile.email}</p>
          <br />
          <br />
          <button onClick={handleLogout}>Log out</button>
        </div>
      ) : (
        <button onClick={login}>Sign in with Google*</button>
      )}
      <div style={{ marginTop: "20px" }}>
        <hr style={{ width: "50%", margin: "auto" }} />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "30vw",
          }}
        >
          <label>
            <input
              type="checkbox"
              checked={isChecked1}
              onChange={() => setIsChecked1(!isChecked1)}
            />
            I confirm the deletion of my account.
            <span>
              View our <a target="_blank" href="https://arogriha.github.io/privacy/">Privacy Policy</a> and{" "}
              <a target="_blank" href="https://arogriha.github.io/terms/">Terms and Conditions</a>*.
            </span>
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              checked={isChecked2}
              onChange={() => setIsChecked2(!isChecked2)}
            />
            I understand that this action is irreversible.*
          </label>
        </div>
        <button
          style={{
            backgroundColor: isSubmitting || !profile || !isChecked1 || !isChecked2 ? "#d3d3d3" : "green",
            padding: "10px 20px", // Increased horizontal padding
            borderRadius: 4,
            color: "black"
          }}
          onClick={handleSubmit}
          disabled={!isChecked1 || !isChecked2 || !profile || isSubmitting}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AccountPage;